import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueNoty from 'vuejs-noty'

// Bootstrap utilities
import '@/assets/scss/bootstrap4/scss/b4utilities.scss'
import '@/assets/scss/bootstrap4/scss/bootstrap-grid.scss'

// Noty CSS
import 'vuejs-noty/dist/vuejs-noty.css'

// api routes
import API from './core/apiRoutes'

Vue.use(VueMaterial)
Vue.use(VueAxios, axios)
Vue.use(VueNoty)

Vue.config.productionTip = false

Vue.prototype.$API = API

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
