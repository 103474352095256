<template>
  <div class="loading-indicator">
    <md-progress-spinner
      :md-diameter="30"
      :md-stroke="3"
      md-mode="indeterminate"
    />
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

/* Absolute Center Spinner */
.loading-indicator {
  position: fixed;
  z-index: 10003;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading-indicator:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
</style>
