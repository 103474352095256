<template>
  <div>
    <label
      v-if="label !== ''"
      class="md-form-label"
      style="display: block; margin-bottom: -10px; text-align: left; color: #aaa;"
    >
      {{ label }}
    </label>
    <md-radio
      v-for="item in options"
      :key="item.value"
      v-model="result"
      :value="item.value"
    >
      {{ item.label }}
    </md-radio>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    name: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      result: null
    }
  },
  watch: {
    value (val) {
      this.result = val
    },
    result (value) {
      this.$emit('input', value)
    }
  },
  mounted () {
    this.result = this.value
  }
}
</script>

<style>
.options .md-radio {
  display: flex !important;
}
.md-list.md-theme-default.md-triple-line .md-list-item-text :nth-child(3) {
  color: rgba(0,0,0,0.87) !important;
}
</style>
