<template>
  <div>
    <FullPageLoader v-if="loading" />
    <div
      v-if="examData && step === 1"
      v-html="examData.exam.body_html"
    />
    <div v-if="examData && step === 2">
      <ul class="exam">
        <li
          v-for="(q,i) of form.answers"
          :key="`q_${q.number}${!q.options.length ? `_${i}` : ''}`"
        >
          <template v-if="!q.options.length">
            <h3
              class="fullText"
              v-html="q.question"
            />
          </template>
          <template v-else>
            <h3 v-html="`${q.number}${q.question}`" />
            <p v-if="q.help_text">
              {{ q.help_text }}
            </p>

            <div class="options">
              <form-radio
                v-model="q.placement_answer_id"
                label="Options"
                name="radio"
                :options="q.options"
              />
            </div>
          </template>
        </li>
      </ul>
    </div>
    <div v-if="examData && step === 3">
      <div v-html="examData.exam.confirm_html" />

      <div class="score">
        Your Score

        <h2>{{ finalScore.toFixed(2) }}%</h2>
      </div>
    </div>

    <div
      v-if="step < 3"
      class="text-center"
    >
      <md-button
        class="md-primary md-raised"
        @click="nextStep"
      >
        Continue
      </md-button>
    </div>
  </div>
</template>

<script>
import FormRadio from '@/components/FormRadio'
import FullPageLoader from '@/components/FullPageLoader'
import services from '@/core/services'

export default {
  components: {
    FormRadio,
    FullPageLoader
  },
  data: () => ({
    examData: null,
    step: 1,
    form: {
      answers: []
    },
    finalScore: 0,
    loading: false
  }),
  mounted () {
    this.getTest()
  },
  methods: {
    getTest () {
      this.loading = true

      const service = services(this.$route.params.apptoken)

      service
        .get(this.$API.EXAM + this.$route.params.token)
        .then((res) => {
          this.examData = res.data.data
          this.form.placement_scheduled_id =
            this.examData.placement_scheduled_id

          let count = 1
          this.form.answers = this.examData.exam.questions
            .sort((a, b) => a.placement_exam_question.sort > b.placement_exam_question.sort ? 1 : -1)
            .map((x) => {
              const res = {
                number: !x.is_text ? `#${count} ` : null,
                sort: x.placement_exam_question.sort,
                question: x.question,
                help_text: x.help_text,
                placement_question_id: x.placement_question_id,
                placement_answer_id: null,
                options: x.answers.map((z) => ({
                  value: z.placement_answer_id,
                  label: z.text
                }))
              }

              if (!x.is_text) { count += 1 }

              return res
            })
        })
        .catch((err) => {
          console.log(err)
          this.$router.push({ name: 'Home' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveTest () {
      this.loading = true
      const answers = []

      this.form.answers.forEach((x, index) => {
        if (x.placement_answer_id) {
          answers.push({
            placement_question_id: x.placement_question_id,
            placement_answer_id: x.placement_answer_id
          })
        }
      })
      const service = services(this.$route.params.apptoken)

      service
        .post(this.$API.EXAM + this.$route.params.token, { answers })
        .then((res) => {
          this.$noty.success('Thanks! Your test has been submitted!')
          this.$router.push({
            name: 'ExamResults',
            params: this.$route.params
          })
        })
        .catch((err) => {
          this.$noty.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    startTest () {
      const service = services(this.$route.params.apptoken)

      service
        .post(this.$API.EXAM + this.$route.params.token + '/start')
    },
    nextStep () {
      if (this.step === 2) this.saveTest()
      else if (this.step === 1) {
        this.startTest()
        this.step++
      } else this.step++
    }
  }
}
</script>

<style lang="scss">
ul.exam {
  padding: 0;

  li {
    list-style-type: none;
    background: #fff;
    border-radius: 0.5em;
    padding: 10px 20px;
    font-size: 90%;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .options {
      margin-top: 20px;
    }

    .md-radio {
      align-items: center;

      .md-radio-label {
        height: auto;
        flex-basis: 100%;
      }
    }
  }
}
.fullText p {
  font-weight: 400;
}
.score {
  text-align: center;
  padding-top: 50px;
  font-weight: 600;
  text-transform: uppercase;

  h2 {
    font-size: 3em;
    color: #00c4ee;
    font-weight: 800;
    margin-top: 15px;
  }
}
</style>
