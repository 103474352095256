<template>
  <div>
    <FullPageLoader v-if="loading" />

    <h4 v-if="test">
      Exam Results - Score: <span class="result">{{ test.score }}%</span> |
      Result Level: <span class="result">{{ test.level.name }}</span>
    </h4>

    <ul
      v-if="test"
      class="exam_answers"
    >
      <li
        v-for="(q, i) of test.answers"
        :key="i"
        :class="{
          error: !q.answer.is_correct,
        }"
      >
        <h3>#{{ i + 1 }} - {{ trimHtml(q.question.question) }}</h3>
        <p v-if="q.question.help_text">
          {{ q.help_text }}
        </p>

        <div class="answer">
          Answer: {{ q.answer.text }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import FullPageLoader from '@/components/FullPageLoader.vue'
import services from '@/core/services'
import string from '@/mixins/string.js'

export default {
  components: {
    FullPageLoader
  },
  mixins: [string],
  data: () => ({
    test: null,
    loading: false
  }),
  mounted () {
    this.getResults()
  },
  methods: {
    getResults () {
      this.loading = true
      const service = services(this.$route.params.apptoken)

      service
        .get(this.$API.EXAM_RESULT.replace(':token', this.$route.params.token))
        .then(({ data }) => {
          this.test = data.data
        })
        .catch((err) => {
          console.log(err)
          // this.$router.push({ name: 'Home' })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style>
.exam_answers {
  padding: 0;
  list-style: none;
}
.exam_answers li {
  padding: 1em;
  background-color: #00ff2247;
}
.exam_answers li:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.exam_answers li.error {
  background-color: #ff000047;
}
.result {
  opacity: 0.7;
  color: #007bff;
}
</style>
